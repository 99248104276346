body {
  background: #f0ece8;
}
/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: local('Raleway Bold'), local('Raleway-Bold'),
    url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwJYtWqhPAMif.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: local('Raleway Bold'), local('Raleway-Bold'),
    url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwJYtWqZPAA.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
.styledLogo {
  grid-column: 1 / span 7;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

/* canvas {
  width: 200px;
  height: 100px;
  background: blue;
  -moz-border-radius: 100px / 50px;
  -webkit-border-radius: 100px / 50px;
  border-radius: 100px / 50px;
}
.my_svg {
  width: 750px;
  height: 500px;
  -moz-border-radius: 100px / 50px;
  -webkit-border-radius: 100px / 50px;
  border-radius: 150px / 250px;
} */
table.resulttable {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  min-width: 100%;
  background: #fff;
  color: #555;
  overflow-x: scroll;
  table-layout: fixed;
}
.resulttable td,
.resulttable th {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  text-align: left;
  padding: 8px;

  /* word-wrap: break-word; */
  text-align: center;
}
.resulttable td {
  min-width: 200px;
}

.resulttable td:first-child {
  text-align: left;
}

.resulttable th:first-child {
  border: 0;
}
.resulttable th {
  background-color: #fff;
  color: #fc0d1c;
}
.resulttable th,
.resulttable td {
  width: 200px;
}
.resulttable tr:nth-child(even) {
  background-color: #fafafa;
}
.resulttable tr:nth-child(odd) {
  background-color: #fff;
}
.resulttable tr:first-child {
  color: #666;
  background-color: #fff6f5;
  font-weight: bold;
}
.resulttable tr:first-child th {
  border-top: none;
}
.resulttable tr:last-child td {
  border-bottom: none;
}
.activeLink {
  margin-left: 15px;
}
.DateRangePicker {
  margin-top: 15px;
  font-size: 14px;
  font-family: sans-serif;
}
.DateInput_1 {
  margin: 10px;
}
.DateInput_input {
  padding: 0;
}

/* .full-height-container {
  height: 100%;
} */
.full-height-container {
  min-height: 100%;
  position: relative;
  top: 50%;
}

.loader-container {
  width: 120px;
  height: 120px;
  padding: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  margin-left: -60px;
  margin-top: 40px;
}

.loader {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  border-top: 16px solid #4885ed;
  border-right: 16px solid #3cba54;
  border-bottom: 16px solid #db3236;
  border-left: 16px solid #f4c20d;
  border-top-color: #d83d28;
  border-right-color: #e06453;
  border-bottom-color: #ae301f;
  border-left-color: #e57e70;
  animation: spin 2s linear infinite;
}
.searchbar > div,
.searchbar input {
  font-size: 14px !important;
  color: #808080 !important;
  background: none !important;
  font-family: sans-serif;
}
.searchbar > div {
  display: none !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* .checkbox-container {
  position: relative;
  left: 150px;
} */
.small-text-box {
  width: 50%;
}

#DatePicker {
  height: 40px;
  line-height: inherit;
  border-color: #ccc;
  text-align: right;
}
#DatePicker .DateInput,
#DatePicker .DateInput_input {
  background: transparent;
}
#DatePicker .DateRangePickerInput__withBorder {
  border: 1px solid #ccc;
  border-radius: 4px;
}
#DatePicker .DateInput_input__focused {
  border-bottom: 2px solid #b05249;
}
#DatePicker .DateInput_displayText__focused {
  background: #dd4b39;
  color: #fff;
}
#DatePicker .DateRangePickerInput_arrow_svg {
  fill: #ccc;
}
#DatePicker .DateRangePicker_picker {
  z-index: 100;
}
.DateRangePicker {
  margin-top: 0;
  height: 40px;
  line-height: inherit;
}
#DatePicker .DateInput_input {
  padding: 8px 11px 6px;
  font-size: 14px;
  color: #333;
}
.projectIcons {
  font-size: 35px;
  text-align: center;
  margin: 0 auto;
}
.link-container {
  height: 150px;
}
.react-select-container {
  height: 100%;
  color: #fff;
}
.projectIconsSingle {
  font-size: 100px;
  text-align: center;
  margin: 0 auto;
}
#DatePicker .CalendarDay__selected_span {
  background: #ea8b7f;
  border: 1px solid #cb7066;
}
#DatePicker .CalendarDay__selected,
#DatePicker .CalendarDay__selected:active,
#DatePicker .CalendarDay__selected:hover {
  background: #cb7066;
  border: 1px solid #b05249;
}
#DatePicker .CalendarDay__selected_span {
  color: #fff;
}

#DatePicker .DayPickerKeyboardShortcuts_show__bottomRight:hover {
  border-right: 33px solid #dc837b;
}

#DatePicker .DayPickerKeyboardShortcuts_show__bottomRight {
  border-right: 33px solid #b05249;
}

#DatePicker .CalendarDay__hovered_span {
  background: #cb7066;
  border: 1px solid #b05249;
  color: #eee;
}

.logo {
  grid-column: span 8;
}
.span-2 {
  grid-column: span 2;
}
.span-3 {
  grid-column: span 3;
}
.span-4 {
  grid-column: span 4;
}
.span-6 {
  grid-column: span 6;
}
.span-8 {
  grid-column: span 8;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  top: 50%;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}
.lds-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #d83d28;
  margin: -3px 0 0 -3px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1000px) {
  #searchBarMedia {
    margin-top: 0px;
  }
}

.textStyle{
  font-size: 11px;
  color: red;
}

.swal-title {
  color: rgb(0 0 0);
  font-size: 20px;
  border-bottom: 1px solid #ddd;
}

.swal-text {
  font-size: 13px;
  color: rgb(0 0 0);
  line-height: 18px;
}

.swal-button { padding: 5px 10px; }