:root {
  background-color: #f0ece8;
}
html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: Montserrat;
}

td {
  font-size: 18px;
}

/* @media screen and (min-width: 600px) {
  #searchBarMedia {
    margin-top: 22px;
    margin-bottom: 22px;
  }
}

@media screen and (max-width: 600px) {
  #searchBarMedia {
    margin-top: 0px;
    margin-bottom: 0px;
  }
} */

/* @media screen and (max-width: 1000px) {
  #searchBarMedia {
    margin-top: 0px;
  }
} */
